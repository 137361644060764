import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "language-menu-container" },
      [
        _c(
          VMenu,
          {
            attrs: { "offset-y": "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "div",
                      _vm._g(
                        _vm._b(
                          { staticClass: "language-menu-content" },
                          "div",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("span", { staticClass: "language-menu-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.lang === "pt" ? "PT-BR" : "EN-US") +
                              " "
                          ),
                        ]),
                        _c(VIcon, { staticClass: "language-menu-icon" }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              VList,
              {
                staticClass: "language-menu-list",
                attrs: { tile: false, nav: "" },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  VListItem,
                  {
                    key: index,
                    staticClass: "language-menu-list-item",
                    on: {
                      click: function ($event) {
                        return _vm.changeLanguage(item)
                      },
                    },
                  },
                  [
                    _c(VListItemTitle, {
                      domProps: { textContent: _vm._s(item.text) },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }