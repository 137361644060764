var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container-bg" }, [
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "header-container" }, [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticClass: "header-mobile" },
            [
              _c("SideMenu", {
                on: { createWalletLink: _vm.redirect_to_register },
              }),
            ],
            1
          ),
          _vm.partnerLogo !== ""
            ? _c("div", [
                _c("img", {
                  staticClass: "partner-logo-dxa-top",
                  attrs: {
                    src: _vm.gs.get_photo_path(_vm.partnerLogo),
                    alt: "logo",
                  },
                }),
              ])
            : _c("div", { staticClass: "dxa-logo-top" }, [
                _c("img", {
                  staticStyle: { width: "126px !important" },
                  attrs: {
                    src: require("../../../../../../assets/dealboard-logo-white.svg"),
                  },
                }),
              ]),
          _c(
            "div",
            { staticClass: "button-group" },
            [
              _c(
                "span",
                {
                  staticClass: "login-in-link",
                  on: {
                    click: function ($event) {
                      return _vm.handleGoAuth()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("login_2")))]
              ),
              _c("HeaderMenuLink", {
                staticClass: "create-wallet",
                attrs: {
                  target: "_self",
                  text: _vm.$t("get_started"),
                  type: "filled",
                },
                on: { btnFunction: _vm.redirect_to_register },
              }),
              _c(
                "div",
                { staticStyle: { "z-index": "999", "margin-left": "20px" } },
                [_c("LanguageSwitch", { attrs: { lang: _vm.lang } })],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "container" }, [
        _c("section", { staticClass: "section-1-container" }, [
          _c("div", { staticClass: "section1" }, [
            _c("div", { staticClass: "section1-content" }, [
              _c("div", { staticClass: "header-text" }, [
                _c("h2", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("invest_best_companies")) + " "),
                ]),
              ]),
              _c("p", { staticClass: "header-subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("start _investing_private_companies")) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "redirect-button-container" },
                [
                  _c("HeaderMenuLink", {
                    staticClass: "link-filled-size",
                    attrs: {
                      target: "_self",
                      text: _vm.$t("meet_opportunities"),
                      type: "filled",
                      "data-test": "alphaUser:createWalletBtn",
                    },
                    on: { btnFunction: _vm.redirect_to_register },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "card-container" }, [
              _c("div", { staticClass: "header-label" }, [
                _c("h2", { staticClass: "card-header-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("landing_graphic_title")) + " "),
                ]),
                _c("div", { staticClass: "card-header-text" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("landing_graphic_sub_title")),
                    },
                  }),
                ]),
                _c("div", { staticClass: "graphic-container" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "graphic-info-container" }, [
                    _c(
                      "span",
                      { staticClass: "graphic-source" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "graphic-source-info-icon",
                          attrs: { icon: "fa-solid fa-info" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("investor_welcome_page_graphic_label_3")
                            )
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "graphic-info-footer" }, [
                      _c("div", { staticClass: "graphic-info-footer-label" }, [
                        _c("span", { staticClass: "circle blue" }),
                        _c("span", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("investor_welcome_page_graphic_label_1")
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "graphic-info-footer-label" }, [
                        _c("span", { staticClass: "circle gray" }),
                        _c("span", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("investor_welcome_page_graphic_label_2")
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-container-mobile" }, [
            _c("div", { staticClass: "header-label" }, [
              _c("h2", { staticClass: "card-header-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("landing_graphic_title")) + " "),
              ]),
              _c("div", { staticClass: "card-header-text" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("landing_graphic_sub_title")),
                  },
                }),
              ]),
              _c("div", { staticClass: "graphic-container" }, [
                _c("div", { staticClass: "graphic-image-container" }, [
                  _c("div", { staticClass: "graphic-image-content" }, [
                    _c("img", {
                      staticClass: "dealboard-logo-mobile",
                      attrs: {
                        src: require("../../../../../../assets/welcome-page-logo-mobile.svg"),
                        alt: "Dealboard Gráfico",
                      },
                    }),
                    _c(
                      "span",
                      { staticClass: "graphic-source" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "graphic-source-info-icon",
                          attrs: { icon: "fa-solid fa-info" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("investor_welcome_page_graphic_label_3")
                            )
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "graphic-info-container" }, [
                  _c("div", { staticClass: "graphic-info-footer" }, [
                    _c("div", { staticClass: "graphic-info-footer-label" }, [
                      _c("span", { staticClass: "circle blue" }),
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("investor_welcome_page_graphic_label_1")
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "graphic-info-footer-label" }, [
                      _c("span", { staticClass: "circle gray" }),
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("investor_welcome_page_graphic_label_2")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("section", { staticClass: "section-2-container" }, [
          _c("div", { staticClass: "number-container-logo-container" }, [
            _c("div", { staticClass: "number-container-logo" }, [
              _c("div", { staticClass: "content" }, [
                _vm.partnerLogo !== ""
                  ? _c(
                      "div",
                      { staticClass: "number-group-dxa-partner-logo" },
                      [
                        _c("img", {
                          staticClass: "partner-logo-dxa",
                          attrs: {
                            src: _vm.gs.get_photo_path(_vm.partnerLogo),
                            alt: "logo",
                          },
                        }),
                      ]
                    )
                  : _c("div", { staticClass: "number-group-dxa-logo" }, [
                      _c("img", {
                        staticStyle: { width: "126px !important" },
                        attrs: {
                          src: require("../../../../../../assets/dealboard-logo-white.svg"),
                        },
                      }),
                    ]),
                _c("span", { staticClass: "number-group-dxa-logo-sub-title" }, [
                  _vm._v(_vm._s(_vm.$t("official_private_partner"))),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "section2" }, [
            _c("div", { staticClass: "section2-content" }, [
              _c("div", { staticClass: "number-group" }, [
                _c("div", { staticClass: "number-container" }, [
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.$t("landing_value_under_management"))),
                  ]),
                  _c("span", { staticClass: "number-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("landing_value_label_under_management")) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "number-container" }, [
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.$t("landing_value_global_investor"))),
                  ]),
                  _c("span", { staticClass: "number-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("landing_value_label_global_investor")) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "number-container" }, [
                  _c("span", { staticClass: "number" }, [
                    _vm._v(
                      _vm._s(_vm.$t("landing_value_minimum_contribution"))
                    ),
                  ]),
                  _c("span", { staticClass: "number-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("landing_value_label_minimum_contribution")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("section", { staticClass: "section4" }, [
          _c("span", { staticClass: "steps-label" }, [
            _vm._v(_vm._s(_vm.$t("landing_step_by_step_title"))),
          ]),
          _c("div", { staticClass: "steps-row" }, [
            _c("div", { staticClass: "step-container" }, [
              _vm._m(1),
              _c("span", { staticClass: "step-label" }, [
                _vm._v(_vm._s(_vm.$t("create_wallet"))),
              ]),
              _c("span", { staticClass: "step-description" }, [
                _vm._v(_vm._s(_vm.$t("dealboard_landing_step_by_step_1"))),
              ]),
            ]),
            _c("div", { staticClass: "step-container" }, [
              _vm._m(2),
              _c("span", { staticClass: "step-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dealboard_landing_title_step_by_step_1")) +
                    " "
                ),
              ]),
              _c("span", { staticClass: "step-description" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("dealboard_landing_step_by_step_2")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "step-container" }, [
              _vm._m(3),
              _c("span", { staticClass: "step-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dealboard_landing_title_step_by_step_2")) +
                    " "
                ),
              ]),
              _c("span", { staticClass: "step-description" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("dealboard_landing_step_by_step_3")) + " "
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "redirect-footer-container" },
            [
              _c("HeaderMenuLink", {
                staticClass: "link-filled-size",
                attrs: {
                  target: "_self",
                  text: _vm.$t("meet_opportunities"),
                  type: "filled",
                },
                on: { btnFunction: _vm.redirect_to_register },
              }),
            ],
            1
          ),
        ]),
        _c("footer", { staticClass: "footer" }, [
          _c("div", { staticClass: "footer-row" }, [
            _vm._m(4),
            _c("div", { staticClass: "footer-label-containter" }, [
              _c("span", { staticClass: "footer-label" }, [
                _vm._v(_vm._s(_vm.$t("landing_better_way_to_invest"))),
              ]),
              _c(
                "span",
                {
                  staticClass: "linkedin-icon",
                  attrs: {
                    href: "https://br.linkedin.com/company/dxainvest",
                    target: "_self",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleGoLinkedin()
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "social-logo",
                    attrs: {
                      src: require("../../../../../../assets/linkedin-gray.svg"),
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _c("span", { staticClass: "footer-c" }, [
            _vm._v(
              _vm._s(_vm.$t("dxa_all_rights_reserved_current_point")) + " "
            ),
            _c(
              "span",
              {
                staticClass: "link",
                on: {
                  click: function ($event) {
                    return _vm.handlePrivacyPolicy()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("privacy_policy")))]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "dealboard-logo-desktop",
        attrs: {
          src: require("../../../../../../assets/welcome-page-logo-desktop.svg"),
          alt: "Dealboard",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-number-container" }, [
      _c("span", { staticClass: "step-number" }, [_vm._v("1")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-number-container" }, [
      _c("span", { staticClass: "step-number" }, [_vm._v("2")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-number-container" }, [
      _c("span", { staticClass: "step-number" }, [_vm._v("3")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-logos" }, [
      _c("div", [
        _c("img", {
          attrs: {
            alt: "Dealboard",
            src: require("../../../../../../assets/dealboard-logo-black.svg"),
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }