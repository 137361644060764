var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Slide",
    { staticClass: "side-menu", attrs: { left: "" } },
    [
      _c("a", { staticClass: "dxa-link", attrs: { href: "/auth" } }, [
        _vm._v(_vm._s(_vm.$t("login"))),
      ]),
      _c(
        "a",
        { staticClass: "dxa-link", on: { click: _vm.handlePushRouter } },
        [_vm._v(_vm._s(_vm.$t("create_wallet")))]
      ),
      _c("LanguageSwitch", { attrs: { lang: _vm.lang } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }